<template>
  <div eagle-form-item="selection">
    <v-select
      outlined
      dense
      :loading="loading"
      :items="selectItems"
      :item-text="optionTextKey"
      :item-value="optionValueKey"
      :item-disabled="itemDisabled"
      :placeholder="$t('unselect')"
      :clearable="clearable"
      :multiple="multiple"
      :return-object="returnObject"
      :readonly="readonly"
      :disabled="disabled"
      :error="!!error"
      :error-messages="error"
      :rules="vuetifyRules"
      :autofocus="focus"
      v-model="data"
    >
      <template v-slot:selection="{item}">
        <v-chip color="primary lighten-1">
          <span>{{item[optionTextKey]}}</span>
        </v-chip>
      </template>

      <template v-slot:append-outer v-if="refreshCallback">
        <v-tooltip top>
          <template #activator="{on}">
            <v-btn
              @click="refresh"
              icon
              v-on="on"
              style="margin-top: -5px"
              :loading="loading"
            >
              <v-icon>fa fa-sync-alt</v-icon>
            </v-btn>
          </template>
          <div>{{'action.refresh'| t}}</div>
        </v-tooltip>
      </template>
    </v-select>
  </div>
</template>

<script lang="babel" type="text/babel">
import formItemMixin from '@/components/form/mixins/formItemMixin'
export default {
  mixins: [formItemMixin],
  data: () => ({
    loading: false,
  }),
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.data = window.eagleLodash.cloneDeep(this.storeData)
    },
    async refresh() {
      this.loading = true
      try {
        await this.refreshCallback(this.formInfo)
      } catch (error) {
        console.warn(error)
      }
      this.loading = false
    },
  },
  computed: {
    itemDisabled() {
      if(typeof this.formDataItem.itemDisabled === 'function') {
        return (item) => this.formDataItem.itemDisabled(item, window.eagleLodash.cloneDeep(this.formMeta))
      }
      return () => false
    },
    refreshCallback() {
      if(typeof this.formDataItem.refreshCallback === 'function') {
        return this.formDataItem.refreshCallback
      }
      return null
    },
    clearable() {
      return this.formDataItem.clearable === true
    },
    returnObject() {
      return this.formDataItem.returnObject === true
    },
    multiple() {
      return this.formDataItem.multiple === true
    },
    optionTextKey() {
      return this.formDataItem.optionTextKey || 'text'
    },
    optionValueKey() {
      return this.formDataItem.optionValueKey || 'value'
    },
    selectItems() {
      return this.options.map(option => {
        let text = option[this.optionTextKey]
        const value = option[this.optionValueKey]
        if(this.formDataItem.optionTextPrefix) {
          text = `${this.formDataItem.optionTextPrefix}.${text}`
        }

        if(this.formDataItem.optionTextSuffix) {
          text = `${text}.${this.formDataItem.optionTextSuffix}`
        }

        if(typeof this.formDataItem.optionText === 'function') {
          text = this.formDataItem.optionText(option[this.optionTextKey], option)
        }

        let meta = null
        if(typeof this.formDataItem.optionMeta === 'function') {
          meta = this.formDataItem.optionMeta(option)
        }

        return {
          [this.optionTextKey]: this.$t(text),
          [this.optionValueKey]: value,
          ...meta
        }
      })
    },
    options() {
      if(typeof this.formDataItem.options === 'function') {
        return this.formDataItem.options(this.formInfo)
      }

      if(!Array.isArray(this.formDataItem.options)) {
        return []
      }

      const firstOption = this.formDataItem.options[0]
      if(typeof firstOption != 'object') {
        return this.formDataItem.options.map(option => ({
          [this.optionTextKey]: option,
          [this.optionValueKey]: option,
        }))
      }

      return this.formDataItem.options
    },
  },
  watch: {
    data: {
      deep: true,
      handler() {
        if(window.eagleLodash.isEqual(this.data, this.storeData)) return
        this.sync()
      },
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
